/* eslint-disable max-len */
/* eslint-disable no-console */

import "./Reservation.scss";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HEADER_TYPE, useHeader } from "providers/header-provider";
import { ImageElement } from "elements/image/Image";
import { bemElement } from "utils/bem-class-names";
import { handleGoBack } from "utils/handleGoBack";
import { OrderItem } from "components/order-item/OrderItem";
import FormattedTextModal from "components/modals/formatted-text-modal/FormattedTextModal";
import { Button } from "elements/button/Button";
import { COLORS } from "models/colors";
import { VARIANTS } from "models/variants";
import TwoColsLayout from "components/layout/two-cols-layout/TwoColsLayout";
import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";
import { IconButton } from "elements/icon-button/IconButton";
import Footer from "components/footer/Footer";
import { useCart } from "providers/cart-provider";
import { getRelativeDateTime } from "utils/date-time";
import { PaymentSection } from "./payment-section/PaymentSection";
import { useUser } from "providers/user";
import { Desktop, Mobile } from "components/responsive/Responsive";
import { AuthSection } from "./auth-section/AuthSection";
import useResponsive from "hooks/useResponsive";
import { TitleSubtitle } from "components/header/sections/title-subtitle/title-subtitle";
import { IBookAppointmentServiceFull } from "providers/cart-provider.utils";
import { TUser } from "types";
import { getRefundAndCancellationHtml } from "../../utils/get-refund-and-cancellation-html";
import { clearCache } from "utils/cacheHelper";
import { generateGoogleMapsLink } from "routes/location/sections/LocationInfoLink/LocationInfoLink";
import { Elements } from "@stripe/react-stripe-js";
import { stripePromise } from "stripeConfig";

const baseClassName = "reservation-page";
const bem = bemElement(baseClassName);

interface IOrder {
  services: IBookAppointmentServiceFull[];
  user: TUser | null;
}

export const Reservation = (): JSX.Element | null => {
  const { cart, totalPrice: subtotal, artists } = useCart();
  const { user, isLoggedIn } = useUser();
  const navigate = useNavigate();
  const { setData } = useHeader();
  const [showTextModal, setShowTextModal] = useState<boolean>(false);
  const [textModalData, setTextModalData] = useState<{
    title: string;
    text: string;
  }>();
  const [showPaymentForm, setShowPaymentForm] = useState<boolean>(false);
  const { isDesktop } = useResponsive();
  const [sortedServices, setSortedServices] = useState<{
    [key: number]: IOrder;
  }>({});

  const userPaysFees = cart.location?.company?.user_pays_fees;
  const totalPrice = useMemo(() => {
    let totalAmount = subtotal;
    if (userPaysFees) {
      totalAmount += totalAmount * 0.04;
    }
    return totalAmount;
  }, [subtotal, userPaysFees]);

  const getReceipt = (): JSX.Element => {
    return (
      <div className={bem("right-col-content")}>
        <Breadcrumbs rootCrumbName={"Reservation details"} breadcrumbs={[]} />
        <div className={bem("location-preview")}>
          <ImageElement
            src={cart.location?.cover_photo_url || ""}
            alt={cart.location?.name || ""}
            aspectRatio="16:9"
          />
          <div className={bem("location-preview-data")}>
            <div className={bem("location-preview-left")}>
              <span className={bem("location-preview-name")}>
                {cart.location?.name}
              </span>
              <span className={bem("location-preview-address")}>
                {cart.location?.address_display}
              </span>
            </div>
            <div className={bem("location-preview-right")}>
              <>
                {cart.location?.address_display && (
                  <a
                    href={generateGoogleMapsLink(cart.location.address_display)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IconButton
                      color={COLORS.SECONDARY}
                      iconName="location"
                      variant={VARIANTS.FILLED}
                    />
                  </a>
                )}
              </>
            </div>
          </div>
        </div>
        <div className={bem("order")}>
          <div className={bem("order-header")}>
            <div>
              <span className="font-semibold">Date and time:</span>{" "}
              {getRelativeDateTime(cart.start_time, cart.location?.timezone)}
            </div>
          </div>
          <div className={bem("order-body")}>
            <div className={bem("order-body-title")}>
              <span>Your order:</span>
            </div>
            <div>
              {Object.entries(sortedServices).map(([key, value], i: number) => {
                return (
                  <OrderItem
                    className={bem("order-body-item")}
                    key={key}
                    artists={artists}
                    index={Number(key)}
                    services={value.services}
                    user={value.user}
                    timezone={cart.location?.timezone}
                  />
                );
              })}
            </div>
          </div>
          <div className={bem("order-footer")}>
            <div className={bem("order-footer-price")}>
              <div>Subtotal</div>
              <div>${subtotal.toFixed(2)}</div>
            </div>
            <div className={bem("order-footer-price")}>
              <div>Tax & Fees ({userPaysFees ? 4 : 0}%)</div>
              <div>+${userPaysFees ? (subtotal * 0.04).toFixed(2) : 0}</div>
            </div>
            <div className={bem("order-footer-price-total")}>
              <span>Total price:</span>
              <span>${totalPrice.toFixed(2)}</span>
            </div>
          </div>
        </div>
        <div className={bem("details-footer")}>
          <span>Read our </span>
          <span
            className={bem("details-footer-link")}
            aria-hidden
            onClick={() => {
              setShowTextModal(true);
              setTextModalData({
                title: "Refund & Cancellation",
                text: getRefundAndCancellationHtml(
                  cart.location?.company?.late_cancellation_hours,
                  cart.location?.company?.late_cancellation_charge,
                  cart.location?.company?.no_show_charge,
                  cart.location?.company?.payment_type
                )
              });
            }}
          >
            Refund policy
          </span>
        </div>
      </div>
    );
  };

  const setPageHeader = (): void => {
    setData({
      type: HEADER_TYPE.TYPE_3,
      leftSection: isDesktop ? null : (
        <IconButton
          color={COLORS.SECONDARY}
          iconName="arrow_left"
          onClick={() => handleGoBack(navigate)}
        />
      ),
      rightSection: isDesktop ? null : (
        <>
          {cart.location?.address_display && (
            <a
              href={generateGoogleMapsLink(cart.location.address_display)}
              target="_blank"
              rel="noreferrer"
            >
              <IconButton color={COLORS.SECONDARY} iconName="location" />
            </a>
          )}
        </>
      ),
      centerSection: isDesktop ? null : (
        <TitleSubtitle
          title="Reservation"
          subtitle={
            <span className={bem("header-subtitle")}>
              {cart.location?.name || ""} • {Object.keys(sortedServices).length}{" "}
              people •{" "}
              {getRelativeDateTime(cart.start_time, cart.location?.timezone)}
            </span>
          }
        />
      )
    });
  };

  const setAuthHeader = (): void => {
    setData({
      type: HEADER_TYPE.TYPE_3,
      leftSection: isDesktop ? null : (
        <IconButton
          color={COLORS.SECONDARY}
          iconName="arrow_left"
          onClick={() => handleGoBack(navigate)}
        />
      ),
      rightSection: <></>,
      centerSection: isDesktop ? null : <></>
    });
  };

  const setPaymentHeader = (): void => {
    setData({
      type: HEADER_TYPE.TYPE_3,
      leftSection: isDesktop ? null : (
        <IconButton
          color={COLORS.SECONDARY}
          iconName="arrow_left"
          onClick={() => setShowPaymentForm(false)}
        />
      ),
      rightSection: <></>,
      centerSection: isDesktop ? null : (
        <TitleSubtitle title="Payment" subtitle={<></>} />
      )
    });
  };

  useEffect(() => {
    isLoggedIn ? setPageHeader() : setAuthHeader();
    clearCache();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      if (showPaymentForm) {
        setPaymentHeader();
      } else {
        setPageHeader();
      }
    } else {
      setAuthHeader();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPaymentForm, isLoggedIn, isDesktop]);

  useEffect(() => {
    let _sortedServices: { [key: number]: IOrder } = {};

    cart.services.forEach((service: IBookAppointmentServiceFull) => {
      if (_sortedServices[service.personIndex]) {
        _sortedServices[service.personIndex].services.push(service);
      } else {
        const _user: TUser | null = service.personIndex === 0 ? user : null;
        _sortedServices = {
          ..._sortedServices,
          [service.personIndex]: {
            user: _user,
            services: [service]
          }
        } as { [key: number]: IOrder };
      }
    });

    setSortedServices(_sortedServices);
  }, [cart.services, user]);

  if (!cart.location) return null;

  return (
    <>
      <div className={baseClassName}>
        <Elements stripe={stripePromise}>
          <div className={bem("modals")}>
            <Mobile>
              <>
                {showPaymentForm && <PaymentSection />}
                {!isLoggedIn && <AuthSection />}
                {showPaymentForm || !isLoggedIn ? null : getReceipt()}
              </>
            </Mobile>
          </div>
          <Desktop>
            <TwoColsLayout equalCols separator>
              <div className={bem("left-col-content")}>
                <Breadcrumbs
                  breadcrumbs={[
                    {
                      title: cart.location.name || "",
                      url: `/company/${cart.location.company_id}/location/${cart.location.slug}`
                    },
                    { title: "Reservation", url: "" }
                  ]}
                />
                {isLoggedIn ? <PaymentSection /> : <AuthSection />}
              </div>
              {getReceipt()}
            </TwoColsLayout>
            <Footer />
          </Desktop>
        </Elements>
        {showPaymentForm || !isLoggedIn ? null : (
          <div className="order-controls">
            <div className="controls-data">
              <span className="controls-data-title">Total price:</span>
              <span className="controls-data-value">${totalPrice}</span>
            </div>
            <div className="controls-button">
              <Button
                onClick={() => setShowPaymentForm(true)}
                className="flexible"
                color={COLORS.SECONDARY}
                text="Go to payment"
                cyId="go-to-payment-button"
                variant={VARIANTS.FILLED}
              />
            </div>
          </div>
        )}
      </div>
      <FormattedTextModal
        show={showTextModal}
        onHide={() => setShowTextModal(false)}
        title={textModalData?.title}
        text={textModalData?.text || ""}
      />
    </>
  );
};
